const videos = [
    // grade6
    [   
        {
            link : 'https://www.youtube.com/watch?v=eJyIv8adVlU',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=eJyIv8adVlU',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=eJyIv8adVlU',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=eJyIv8adVlU',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=eJyIv8adVlU',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=eJyIv8adVlU',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=eJyIv8adVlU',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=eJyIv8adVlU',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=eJyIv8adVlU',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=eJyIv8adVlU',
            grade : 6,
        },
    ],
    // grade7
    [
        {
            link : 'https://www.youtube.com/watch?v=l_sJ9xA1yG0',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=l_sJ9xA1yG0',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=l_sJ9xA1yG0',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=l_sJ9xA1yG0',
            grade : 6,
        },
    ],
    // grade8
    [
        {
            link : 'https://www.youtube.com/watch?v=l_sJ9xA1yG0',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=l_sJ9xA1yG0',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=l_sJ9xA1yG0',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=l_sJ9xA1yG0',
            grade : 6,
        },
    ],
    // grade9
    [
        {
            link : 'https://www.youtube.com/watch?v=l_sJ9xA1yG0',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=l_sJ9xA1yG0',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=l_sJ9xA1yG0',
            grade : 6,
        },
        {
            link : 'https://www.youtube.com/watch?v=l_sJ9xA1yG0',
            grade : 6,
        },
    ]
];

export default videos;